<template>
  <div class="p-grid card">
    <p-toolbar class="p-mb-4 p-col-12">
      <template #left>
        <h5 class="p-m-0">Pedidos</h5>
      </template>
    </p-toolbar>

    <div class="p-col-12 p-md-3 p-fluid">
      <div class="p-field">
        <label>Listar Cancelados</label>
        <p-inputswitch v-model="dataParams.listarCancelados" @change="find" />
      </div>
    </div>
    <div class="p-col-12 p-md-3 p-fluid">
      <div class="p-field">
        <label>Local da Compra</label>
        <p-dropdown
          v-model="dataParams.localCompra"
          required
          :options="locaisCompra"
          optionValue="value"
          optionLabel="name"
          placeholder="Todos"
          showClear
          style="min-width: 200px"
          @change="find"
          class="p-mr-2 p-mb-2"
        />
      </div>
    </div>
    <div class="p-col-12 p-md-3 p-fluid">
      <div class="p-field">
        <label>Status</label>
        <p-multiSelect
          v-model="statusMarcados"
          :options="pedidoStatus"
          placeholder="Todos os status"
          @change="find"
        />
      </div>
    </div>
    <div class="p-col-12 p-md-3 p-fluid">
      <div class="p-field">
        <label>Pesquisa</label>
        <p-inputtext
          v-model="dataParams.filter"
          @keypress.enter="find"
          placeholder="Pesquisar..."
        />
      </div>
    </div>
    <div class="p-col-12 p-d-flex p-ai-center p-jc-end">
      <p-button @click="find" label="Consultar" />
    </div>

    <p-divider />

    <p-datatable
      ref="dt"
      :value="data"
      v-model:selection="selectedItens"
      data-key="id"
      :loading="loading"
      :first="0"
      :lazy="true"
      :paginator="true"
      :rows="dataParams.rows"
      :totalRecords="dataParams.totalRecords"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10, 25]"
      currentPageReportTemplate="{first} - {last} de {totalRecords} registros"
      @page="onPage"
      @sort="onSort"
      responsiveLayout="stack"
      stripedRows
      class="p-col-12"
    >
      <template #empty>
        <div class="p-text-center p-mt-5 p-mb-5">Nenhum registro encontrado!</div>
      </template>

      <p-column field="e.id" header="Nº" :sortable="true">
        <template #body="{ data }">
          <b>{{ data.id }}</b>
        </template>
      </p-column>
      <p-column field="e.created_at" header="Data" :sortable="true">
        <template
          #body="{ data }"
        >{{ new Date(data.createdAt).toLocaleString() }}</template>
      </p-column>
      <p-column
        field="e.user_id"
        header="Cliente"
        :sortable="true"
        style="min-width: 300px"
      >
        <template #body="{ data }">
          <div class="p-d-flex p-ai-center">
            <x-avatar
              class="p-mr-2"
              style="
                min-width: 30px;
                width: 30px;
                min-height: 30px;
                height: 30px;
              "
              :user="data.user"
            />
            <a style="cursor: pointer" @click="userView.show(data.user.id)">
              {{
                data.user.name
              }}
            </a>
          </div>
        </template>
      </p-column>
      <p-column field="e.total" header="Total" :sortable="true">
        <template #body="{ data }">{{ $formatCurrency(data.total) }}</template>
      </p-column>
      <p-column header="Local da Compra">
        <template #body="{ data }">
          <span
            v-if="!data.pontoApoio || ['pa', 'pa_ativacao'].includes(data.tipo)"
          >EMPRESA</span>
          <a
            v-else
            style="cursor: pointer"
            @click="paView.show(data.pontoApoio.id)"
          >PA {{ data.pontoApoio.apelido }}</a>
        </template>
      </p-column>
      <p-column field="e.status" header="Status" :sortable="true">
        <template #body="slotProps">
          <Status :value="slotProps.data.status" />
        </template>
      </p-column>
      <p-column bodyStyle="text-align: right; justify-content: flex-end;">
        <template #body="slotProps">
          <p-button
            v-if="canCancelar(slotProps.data)"
            class="p-button-help p-mr-1 p-mt-1"
            style="background: #ec1de0"
            @click="cancelar(slotProps.data)"
            label="Cancelar"
          />
          <p-button
            v-if="slotProps.data.status === 'pendente'"
            label="Pagar"
            @click="pagar(slotProps.data)"
            class="p-mr-1 p-mt-1"
          />
          <p-button
            label="Detalhes"
            class="p-button-secondary p-mt-1"
            @click="detalhes(slotProps.data)"
          />
        </template>
      </p-column>
    </p-datatable>
  </div>

  <x-user-view
    v-model:visible="userView.visible"
    :title="userView.title"
    :id="userView.id"
  />
  <x-ponto-apoio-view v-model:visible="paView.visible" :id="paView.id" />
</template>

<script lang="ts">
import { getInstance, Pedido, pedidoStatus } from "@/services/PedidoService";
import { DataTableControl } from "@/services/Services";
import { defineComponent, onMounted, reactive, ref } from "vue";
import Status from "../../pedidos/Status.vue";
import { useShowUser } from "@/components/UserView.vue";
import { useCancelar } from "@/composables/pedido";
import { useShowPontoApoio } from "@/components/PontoApoioView.vue";
import { filterKeys } from "@/utils/object";

const FILTER_STORAGE_KEY = "admin/pedidos";

interface PedidoDataTableControl extends DataTableControl {
  listarCancelados: boolean;
  localCompra: string;
}

const locaisCompra = [
  {
    value: "empresa",
    name: "Empresa",
  },
  {
    value: "pa",
    name: "Pontos de Apoio",
  },
];

export default defineComponent({
  components: { Status },
  data() {
    return {
      cadastroVisible: false,
      deleteItemDialog: false,
      item: {},
      selectedItens: null,
      submitted: false,
    };
  },
  setup() {
    const data = ref<Pedido[]>([]);
    const loading = ref(false);
    const dataParams = reactive<PedidoDataTableControl>({
      rows: 10,
      page: 0,
      sortField: "e.id",
      sortOrder: -1,
      totalRecords: 0,
      filter: "",
      listarCancelados: false,
      localCompra: null!,
    });
    const statusMarcados = ref([]);

    async function find() {
      loading.value = true;
      try {
        const result = await getInstance().find({
          q: dataParams.filter,
          status: statusMarcados.value.join(","),
          first: dataParams.rows,
          page: dataParams.page + 1,
          sortField: dataParams.sortField,
          sortOrder: dataParams.sortOrder > 0 ? "ASC" : "DESC",
          listarCancelados: dataParams.listarCancelados,
          localCompra: dataParams.localCompra,
        });
        data.value = result.data;
        dataParams.totalRecords = result.paginatorInfo.total;
        localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(Object.assign(
          filterKeys(dataParams, [
            "filter",
            "listarCancelados",
            "localCompra"
          ]), {
          statusMarcados: statusMarcados.value
        }
        )));
      } finally {
        loading.value = false;
      }
    }

    const userView = useShowUser();
    const paView = useShowPontoApoio();
    const { canCancelar, cancelar: doCancelar } = useCancelar();

    onMounted(() => {
      try {
        const storage = JSON.parse(localStorage.getItem(FILTER_STORAGE_KEY) || "{}");
        dataParams.filter = storage.filter || "";
        dataParams.listarCancelados = storage.listarCancelados || false;
        dataParams.localCompra = storage.localCompra || "empresa";
        statusMarcados.value = storage.statusMarcados || [];
      } finally {

      }
      find();
    });

    return {
      statusMarcados,
      pedidoStatus,
      locaisCompra,
      canCancelar,
      async cancelar(p: Pedido) {
        if (await doCancelar(p)) {
          find();
        }
      },
      userView,
      loading,
      dataParams,
      find,
      data,
      paView,
    };
  },
  methods: {
    onPage(evt: any) {
      this.dataParams.rows = evt.rows;
      this.dataParams.page = evt.page;
      this.find();
    },
    onSort(evt: any) {
      this.dataParams.sortField = evt.sortField;
      this.dataParams.sortOrder = evt.sortOrder;
      this.find();
    },
    detalhes(pedido: Pedido) {
      this.$router.push({
        name: "pedido-detalhes",
        params: {
          id: pedido.id,
        },
      });
    },
    pagar(pedido: Pedido) {
      this.$router.push({
        name: "pedido-checkout",
        params: {
          id: pedido.id,
        },
      });
    },
  },
});
</script>
