
import { getInstance, Pedido, pedidoStatus } from "@/services/PedidoService";
import { DataTableControl } from "@/services/Services";
import { defineComponent, onMounted, reactive, ref } from "vue";
import Status from "../../pedidos/Status.vue";
import { useShowUser } from "@/components/UserView.vue";
import { useCancelar } from "@/composables/pedido";
import { useShowPontoApoio } from "@/components/PontoApoioView.vue";
import { filterKeys } from "@/utils/object";

const FILTER_STORAGE_KEY = "admin/pedidos";

interface PedidoDataTableControl extends DataTableControl {
  listarCancelados: boolean;
  localCompra: string;
}

const locaisCompra = [
  {
    value: "empresa",
    name: "Empresa",
  },
  {
    value: "pa",
    name: "Pontos de Apoio",
  },
];

export default defineComponent({
  components: { Status },
  data() {
    return {
      cadastroVisible: false,
      deleteItemDialog: false,
      item: {},
      selectedItens: null,
      submitted: false,
    };
  },
  setup() {
    const data = ref<Pedido[]>([]);
    const loading = ref(false);
    const dataParams = reactive<PedidoDataTableControl>({
      rows: 10,
      page: 0,
      sortField: "e.id",
      sortOrder: -1,
      totalRecords: 0,
      filter: "",
      listarCancelados: false,
      localCompra: null!,
    });
    const statusMarcados = ref([]);

    async function find() {
      loading.value = true;
      try {
        const result = await getInstance().find({
          q: dataParams.filter,
          status: statusMarcados.value.join(","),
          first: dataParams.rows,
          page: dataParams.page + 1,
          sortField: dataParams.sortField,
          sortOrder: dataParams.sortOrder > 0 ? "ASC" : "DESC",
          listarCancelados: dataParams.listarCancelados,
          localCompra: dataParams.localCompra,
        });
        data.value = result.data;
        dataParams.totalRecords = result.paginatorInfo.total;
        localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(Object.assign(
          filterKeys(dataParams, [
            "filter",
            "listarCancelados",
            "localCompra"
          ]), {
          statusMarcados: statusMarcados.value
        }
        )));
      } finally {
        loading.value = false;
      }
    }

    const userView = useShowUser();
    const paView = useShowPontoApoio();
    const { canCancelar, cancelar: doCancelar } = useCancelar();

    onMounted(() => {
      try {
        const storage = JSON.parse(localStorage.getItem(FILTER_STORAGE_KEY) || "{}");
        dataParams.filter = storage.filter || "";
        dataParams.listarCancelados = storage.listarCancelados || false;
        dataParams.localCompra = storage.localCompra || "empresa";
        statusMarcados.value = storage.statusMarcados || [];
      } finally {

      }
      find();
    });

    return {
      statusMarcados,
      pedidoStatus,
      locaisCompra,
      canCancelar,
      async cancelar(p: Pedido) {
        if (await doCancelar(p)) {
          find();
        }
      },
      userView,
      loading,
      dataParams,
      find,
      data,
      paView,
    };
  },
  methods: {
    onPage(evt: any) {
      this.dataParams.rows = evt.rows;
      this.dataParams.page = evt.page;
      this.find();
    },
    onSort(evt: any) {
      this.dataParams.sortField = evt.sortField;
      this.dataParams.sortOrder = evt.sortOrder;
      this.find();
    },
    detalhes(pedido: Pedido) {
      this.$router.push({
        name: "pedido-detalhes",
        params: {
          id: pedido.id,
        },
      });
    },
    pagar(pedido: Pedido) {
      this.$router.push({
        name: "pedido-checkout",
        params: {
          id: pedido.id,
        },
      });
    },
  },
});
